[
  {
    "id": 1,
    "izena": "Aratz Ikastola",
    "lekua": "Hernani",
    "zikloak": [4],
    "helbidea": "Sandiusterri kalea 4, 20120", 
    "emaila": "idazkaritza@aratzikastola.eus",
    "telefonoa": "943 550 233",
    "telefonoOsoa": "+34943550233",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "https://www.aratz.eus/",
    "irudia": "/img/ikastolak/aratz.jpg",
    "irudiaAltEu": "Aratz ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Aratz",
    "top": 16.5,
    "left": 46.58
  },
  {
    "id": 2,
    "izena": "Arizmendi ikastola",
    "lekua": "Arrasate",
    "zikloak": [1,3,4,10,12,20],
    "helbidea": "Otalora Lizentziaduna 8, 20500", 
    "emaila": "arizmendi@arizmendi.eus",
    "telefonoa": "943 772 025",
    "telefonoOsoa": "+34943772025",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "http://www.arizmendi.eus/eu/",
    "irudia": "/img/ikastolak/arizmendi.jpg",
    "irudiaAltEu": "Arizmendi ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Arizmendi",
    "top": 26,
    "left": 31
  },
  {
    "id": 3,
    "izena": "Bernat Etxepare Lizeoa",
    "lekua": "Baiona",
    "zikloak": [5,14],
    "helbidea": "Mayi Ariztia kalea 5, 64100", 
    "emaila": "etxepare@gmail.com",
    "telefonoa": "05 59 50 70 20",
    "telefonoOsoa": "+330559507020",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "https://www.betxepare.eus/eu/",
    "irudia": "/img/ikastolak/bernat-etxepare.jpg",
    "irudiaAltEu": "Bernat Etxepare Lizeoa ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Bernat Etxepare Lizeo",
    "top": 1.4,
    "left": 66.4
  },
  {
    "id": 4,
    "izena": "Harrobia Lanbide Ikastola",
    "lekua": "Bilbo",
    "zikloak": [2,10,11,13,17,24,31],
    "helbidea": "Harrobi Plaza, 4 (sarrera Kontzezio kaletik), 48003", 
    "emaila": "info@harrobia.net",
    "telefonoa": "944 724 366",
    "telefonoOsoa": "+34944724366",
    "telefonoa2": "688 650 113",
    "telefonoOsoa2": "+34688650113",
    "fax": "",
    "url": "http://www.harrobia.net/",
    "irudia": "/img/ikastolak/harrobia.jpg",
    "irudiaAltEu": "Harrobia Lanbide ikastolaren eraikina",
    "irudiaAltEs": "Edificio de Harrobia Lanbide ikastola",
    "top": 14.7,
    "left": 16.7
  },
  {
    "id": 5,
    "izena": "Ibaizabal Ikastola",
    "lekua": "Durango",
    "zikloak": [21,22],
    "helbidea": "Intxaurrondo 54, 48200", 
    "emaila": "idazkaritza@ibaizabalikastola.eus",
    "telefonoa": "946 215 877",
    "telefonoOsoa": "+34946215877",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "https://ibaizabalikastola.eus/",
    "irudia": "/img/ikastolak/ibaizabal.jpg",
    "irudiaAltEu": "Ibaizabal ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Ibaizabal",
    "top": 19.4,
    "left": 29.8
  },
  {
    "id": 6,
    "izena": "Inmakulada Lanbide Ikastola",
    "lekua": "Tolosa",
    "zikloak": [4,6,9,16,21,26,27,36,37],
    "helbidea": "Kondeko Aldapa, 5. B, 20400", 
    "emaila": "idazkaritza@inmakuladatolosa.eus",
    "telefonoa": "943 673 629",
    "telefonoOsoa": "+34943673629",
    "telefonoa2": "678 160 643",
    "telefonoOsoa2": "+34678160643",
    "fax": "",
    "url": "http://www.inmakuladatolosa.eus/",
    "irudia": "/img/ikastolak/inmakulada.jpg",
    "irudiaAltEu": "Inmakulada Lanbide ikastolaren eraikina",
    "irudiaAltEs": "Edificio de Inmakulada Lanbide ikastola",
    "top": 21,
    "left": 44.5
  },
  {
    "id": 7,
    "izena": "Oteitza Lizeo Politeknikoa",
    "lekua": "Zarautz",
    "zikloak": [7,8,15,18,19,25,34],
    "helbidea": "Gipuzkoa kalea 5, 20800", 
    "emaila": "arantxa@oteitzalp.com",
    "telefonoa": "943 111 000",
    "telefonoOsoa": "+34943111000",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "https://www2.oteitzalp.org/",
    "irudia": "/img/ikastolak/oteitza-lizeo.jpg",
    "irudiaAltEu": "Oteitza Lizeo Politeknikoa ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Oteitza Lizeo Politécnica",
    "top": 12.28,
    "left": 43.47
  },
  {
    "id": 8,
    "izena": "Politeknika Txorierri",
    "lekua": "Derio",
    "zikloak": [8,15,18,19,23,28,29,30,32,33,35],
    "helbidea": "Untzaga Ibaia kalea 1, 48160", 
    "emaila": "info@politeknikatxorierri.eus",
    "telefonoa": "944 034 060",
    "telefonoOsoa": "+34944034060",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "http://www.politeknikatxorierri.eus/",
    "irudia": "/img/ikastolak/txorierri.jpg",
    "irudiaAltEu": "Politeknika Txorierri ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Politécnica Txorierri",
    "top": 10.3,
    "left": 17
  },
  {
    "id": 9,
    "izena": "Zurriola Ikastola - J.M. Barandiaran Lizeoa",
    "lekua": "Donostia",
    "zikloak": [20],
    "helbidea": "Indianoene, 1 20013", 
    "emaila": "zurriola@ikastola.eus",
    "telefonoa": "943 272 587",
    "telefonoOsoa": "+34943272587",
    "telefonoa2": "",
    "telefonoOsoa2": "",
    "fax": "",
    "url": "https://zurriolaikastola.eus/",
    "irudia": "/img/ikastolak/zurriola.jpg",
    "irudiaAltEu": "Zurriola ikastolaren eraikina",
    "irudiaAltEs": "Edificio de la ikastola Zurriola",
    "top": 11,
    "left":49.36
  }
]
